@use 'mixins';
@use 'colors';

.subheading {
  color: colors.$grey-warm-400;
}

.title {
  color: colors.$grey-warm-500;
}

.title-dark {
  color: colors.$grey-warm-600;
}

.primary-light {
  color: colors.$primary-400;
}

.primary {
  color: colors.$primary-500-base;
}

.h1 {
  @include mixins.heading(50px, 63px);
}

.h2 {
  @include mixins.heading(38px, 48px);
}

.h3 {
  @include mixins.heading(28px, 35px);
}

.h4 {
  @include mixins.heading(21px, 26px);
}

.h5 {
  @include mixins.heading(16px, 22px);
}

.h6 {
  @include mixins.heading(12px, 15px);
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.all-small-caps {
  font-variant: all-small-caps;
}

.huge-body-text {
  @include mixins.body-text(20px, 25px);
}

.large-body-text {
  @include mixins.body-text(16px, 20px);
}

.large-body-text-semi-bold {
  @include mixins.body-text(16px, 20px);
  font-weight: 600 !important;
}

.main-body-text {
  @include mixins.body-text(14px, 18px);
}

.main-body-text-bold {
  font-weight: 700 !important;
  @include mixins.body-text(14px, 18px);
}

.main-body-text-semi-bold {
  @include mixins.body-text(14px, 18px);
  font-weight: 600 !important;
}

.main-body-text-semi-bold {
  @include mixins.body-text(14px, 18px);
  font-weight: 600 !important;
}

.small-body-text {
  @include mixins.body-text(12px, 15px);
}

.secondary-body-text {
  @include mixins.body-text(10px, 13px);
}

.small-caps-regular {
  @include mixins.body-text(12px, 10px);
  letter-spacing: 0.04em;
}

.small-caps-bold {
  @include mixins.heading(12px, 10px);
}

.medium-caps-bold {
  @include mixins.heading(14px, 18px);
}

.large-caps-bold {
  @include mixins.heading(16px, 18px);
}

%h1 {
  @include mixins.heading(50px, 63px);
}

%h2 {
  @include mixins.heading(38px, 48px);
}

%h3 {
  @include mixins.heading(28px, 35px);
}

%h4 {
  @include mixins.heading(21px, 26px);
}

%h5 {
  @include mixins.heading(16px, 22px);
}

%h6 {
  @include mixins.heading(12px, 15px);
}

%medium-caps-bold {
  @include mixins.heading(14px, 18px);
}

%main-body-text {
  @include mixins.body-text(14px, 18px);
}

%large-caps-bold {
  @include mixins.heading(16px, 18px);
}

%large-body-text {
  @include mixins.body-text(16px, 20px);
}

